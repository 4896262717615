<template>
    <v-container id="contracts" :class="!fromFaq ? 'pt-8 pb-8 pa-0' : 'pa-0'" flat>
        <BannerPage v-if="!fromFaq && contractInfo" class="contracts_banner"
            icon="mdi-file-document-edit " 
            :title="$t('message.contracts_customers_list_header')"></BannerPage>
        
        <v-row v-if="!fromFaq && $t('message.contracts_customers_list_description').length > 0" 
            class="contracts_row_subtitle">
            <v-col cols="12">
                <span class="contracts_subtitle" v-html="$t('message.contracts_customers_list_description')"></span>
            </v-col>
        </v-row>

        <v-dialog v-model="goodsDialog" :width="'60%'"
            @keydown.esc="escKeydown">
            <GoodsList :contractInfo="contractInfo" :contract="contractDialog" @goods-list-cancel="closeDialog"></GoodsList>
        </v-dialog>
        
        <v-skeleton-loader type="article" tile :loading="contractPageLoading">
            <v-expansion-panels class="contracts_expansion_panels pt-2" flat v-model="panel">
                <v-expansion-panel v-for="(itemCtr, i) in items" :key="i" class="ma-2 border">
                    <v-expansion-panel-header class="contractsPanelTitle" @click="getRows(i)">
                        {{ $t("message." + itemCtr.section.names) }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-skeleton-loader type="table" :loading="rowsLoading">
                            <v-card v-if="itemCtr.entries && itemCtr.entries.length > 0" flat>
                                <v-card-title>
                                    <v-checkbox v-if="!fromFaq"
                                        v-model="checkboxStatus"
                                        class="contractsFilterStatus"
                                        :label="$t('message.contracts_customers_checkbox_label')"></v-checkbox>
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        class="contractsFilter"
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        :label="$t('message.contracts_customers_find_label')"
                                        single-line
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table 
                                    :headers="itemCtr.headers"
                                    :items="filteredEntries[i]"
                                    :search="search"
                                    :single-expand="true"
                                    :expanded.sync="expanded"
                                    item-key="n_contract"
                                    :show-expand="contractInfo.contracts_customers_list_details == '1' && contractInfo.contracts_details_enabled == '1' && !fromFaq"
                                    :footer-props="{'items-per-page-text':$t('message.contracts_customers_num_per_page')}"
                                    class="contractsDataGrid">

                                    <template v-slot:item="{ item, headers, expand, isExpanded }">
                                        <tr>
                                            <td v-for="(hdr, j) in headers" :key="'item-' + j" v-bind:style="{ 'text-align': hdr.align }">
                                                <template v-if="hdr.value == 'data-table-expand'">
                                                    <v-icon :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]" 
                                                        @click.stop="expand(!isExpanded)">$expand</v-icon>
                                                </template>
                                                <template v-else-if="hdr.value == 'good'">
                                                    <v-btn icon class="ma-2" @click="openDialog(item)">
                                                        <v-icon> mdi-magnify </v-icon>
                                                    </v-btn>
                                                </template>
                                                 <template v-else-if="hdr.value == 'downloadFaq'">
                                                    <v-btn id="btnPdf" icon @click="downloadFaq(item)"
                                                        class="newFile">
                                                        <v-icon id="pdfIcon"> mdi-file-pdf </v-icon>
                                                    </v-btn>
                                                </template>
                                                <template v-else-if="hdr.format == 'special'">
                                                    <span v-if="hdr.value == 'status'" v-html="getStatus(item[hdr.value])"></span>
                                                    <span v-else-if="hdr.value == 'doc_payment_status'" v-html="getPaymentStatus(item[hdr.value], item.doc_payment_expiry)"></span>
                                                    <span v-else>{{ item[hdr.value] }}</span>
                                                </template>
                                                <span v-else-if="hdr.format == 'bool'">
                                                    <v-simple-checkbox :input-value="item[hdr.value]" value disabled></v-simple-checkbox>
                                                </span>
                                                <template v-else-if="hdr.format == 'number'">
                                                    {{ item[hdr.value] | formatNumber(hdr.format_show) }}
                                                </template>
                                                <span v-else-if="hdr.format == 'amount'">
                                                    {{ item[hdr.value] | formatAmount(hdr.format_show, contractInfo.currency_sigle) }}
                                                </span>
                                                <template v-else-if="hdr.format == 'date'">
                                                    {{ item[hdr.value] | formatDate(hdr.format_show, $t("message.contract_date_not_available")) }}
                                                </template>
                                                <template v-else>{{ item[hdr.value] }}</template>
                                            </td>
                                        </tr>
                                    </template>

                                    <template v-if="contractInfo.contracts_customers_list_details == '1'"
                                        v-slot:expanded-item="{ headers, item}">
                                        <td style="padding:0px; box-shadow:none" :colspan="headers.length">
                                            <ContractDetails :type="item.contracts_customers_type" :item="item" :contract-info="contractInfo"></ContractDetails>
                                        </td> 
                                    </template>
                                </v-data-table>
                            </v-card>
                            <span v-else class="contractsListEmpty" v-html="contractInfo.contracts_customers_list_no_doc_text"></span>
                        </v-skeleton-loader>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-skeleton-loader>

        <Snackbar ref="mySnackbar"></Snackbar> 
    </v-container>
</template>

<script>
import { services } from "../scripts/services/serviceBuilder";
import ContractDetails from './ContractDetails';
import GoodsList from './GoodsList';
import Snackbar from './Snackbar.vue';
import BannerPage from './BannerPage.vue';

export default {
    name: 'contracts',
    components: {
		ContractDetails,
        GoodsList,
        Snackbar,
        BannerPage
	},
    data: function () {
         return {
            goodsDialog: false,
            contractDialog: null,
            contractPageLoading: true,
            contractInfo: null,
            rowsLoading: true,
            expanded: [],
            search: '',
            items: [],
            headerAlign: ['center', 'start', 'end'],
            checkboxStatus: false,
            panel: [],
            refresh: false
        }      
    },
    props: {
        fromFaq: {
            type : Boolean,
            default: false
        }
    },
    computed: {
        filteredEntries: function () {
            var filtered = [];

            for(var i = 0, l = this.items.length; i < l; i++)
            { 
                if (this.items[i].entries)
                {
                    if (!this.checkboxStatus) {
                        filtered.push(this.items[i].entries);
                    }
                    else {
                        var result = this.items[i].entries.filter(item => item.status == 1);
                        filtered.push(result);
                    }
                }
            } 
            
            return filtered;               
        },
        changeLanguage: function() {
            console.log(this.$t('message.contracts_customers_list_details_label'))
            return this.$t('message.contracts_customers_list_details_label');
        }
    },
    methods: {
        setItems: function (data) {
            var that = this;

            data.forEach(d => {
                var text = "";
                var headersFromDto = [];

                d.header.forEach(x => {
                    if (x.field == 'good')
                    {
                        if(that.contractInfo.contracts_details_enabled != '1' || this.fromFaq)
                            return;
                        
                        text = that.$t('message.contracts_customers_list_details_label');
                    }
                    else if (x.field == 'detail')
                    {
                        if(that.contractInfo.contracts_customers_list_details != '1' || this.fromFaq)
                            return;

                        text = that.$t("message.contracts_customers_list_last_label");
                    }
                    else
                    {
                        text = that.$t("message." + x.label);
                    }

                    headersFromDto.push({
                        text: text,
                        align: x.alignment ? that.headerAlign[x.alignment] : 'center',
                        value: x.field == "detail" ? "data-table-expand" : x.field,
                        sortable : x.sortable == 1 ? true : false,
                        class: 'secondaryColorBg white--text',
                        format: x.format,
                        format_show: x.format_show
                    });
                });

                if(this.fromFaq) {
                    headersFromDto.push({
                        text: '',
                        align: 'center',
                        value: "downloadFaq",
                        sortable : false,
                        class: 'secondaryColorBg white--text',
                        format: 'special',
                        format_show: null
                    });
                }

                that.items.push({
                    section: d.section,
                    headers: headersFromDto,
                    entries: []
                });
            });
        },
        closeDialog: function() {
            this.contractDialog = null;
            this.goodsDialog = false;
        },
        downloadFaq: function(item) {
            this.$emit("close-contract", item.id)
        },
        escKeydown: function () {
          this.closeDialog();
        },
        getPaymentStatus: function (value, docPaymentExpiry) {
            switch (value) {
                case '1':
                    return '<span class="contracts_paymentStatus_pay">' + this.$t("message.contract_payment_status_paid") + '</span>';
                case '2':
                    return '<span class="contracts_paymentStatus_verify">' + this.$t("message.contract_payment_status_verify") + '</span>';
                default:
                    if (docPaymentExpiry) {
                        var dpe = new Date(docPaymentExpiry);
                        var now = new Date();
                        if (dpe >= now)
                            return '<span class="contracts_paymentStatus_expiring">' + this.$t("message.contract_payment_status_expiring") + '</span>';
                        else
                            return '<span class="contracts_paymentStatus_notpay">' + this.$t("message.contract_payment_status_notpay") + '</span>';
                    }
                    else {
                        return '<span class="contracts_paymentStatus_notpay">' + this.$t("message.contract_payment_status_notpay") + '</span>';
                    }
            }
        },
        getStatus: function (value) {
            switch (value) {
                case 0:
                    if (this.contractInfo.contracts_customers_icons == '1') {
                        return '<span class="contracts_status_notActive_icon"><i aria-hidden="true" id="errorIcon" class="v-icon notranslate mdi mdi-close-circle-outline theme--light"></i>&nbsp;<span>' +
                            this.$t("message.contract_status_not_active") +
                            '</span></span>';
                    }
                    else {
                        return '<span class="contracts_status_notActive">' + this.$t("message.contract_status_not_active") + '</span>';
                    }
                case 1:
                    if (this.contractInfo.contracts_customers_icons == '1') {
                        return '<span class="contracts_status_active_icon"><i aria-hidden="true" id="errorIcon" class="v-icon notranslate mdi mdi-check-circle-outline theme--light"></i>&nbsp;</span>' +
                            this.$t("message.contract_status_active") +
                            '</span></span>';
                    }
                    else {
                        return '<span class="contracts_status_active">' + this.$t("message.contract_status_not_active") + '</span>';
                    }
                case 2:
                    if (this.contractInfo.contracts_customers_icons == '1') {
                        return '<span class="contracts_status_suspended_icon"><i aria-hidden="true" id="errorIcon" class="v-icon notranslate mdi mdi-alert-circle-outline theme--light"></i>&nbsp;' +
                            this.$t("message.contract_status_suspended") +
                            '</span></span>';
                    }
                    else {
                        return '<span class="contracts_status_suspended">' + this.$t("message.contract_status_suspended") + '</span>';
                    }
                default:
                    return '';
            }
        },
        getRows: function(index) {
            var that=this;

            that.rowsLoading = true;
            services.apiCallerContract.listContractsInView(that.items[index].section.id_contract, that.$cod_client.value)
                .then((response) => {
                    that.items[index].entries = response.data;
                })
                .catch((error) => {
                    console.log(error);
                    that.$refs.mySnackbar.open(that.$t("message.contract_list_error"));
                })
                .finally(() => {
                    that.rowsLoading = false;
                });            
        },
        loadData: function () {
            var that = this;

            that.contractPageLoading = true;

            that.items = [];
            that.contractInfo = [];
            that.detailHeaders = [];

            var requests = [];
            requests.push(services.apiCallerContract.getContractInfo());
            requests.push(services.apiCallerContract.listHeaders(that.$cod_client.value));

            Promise.all(requests)
                .then(responses => {
                    that.contractInfo = responses[0].data;
                    that.setItems(responses[1].data);
                    if(this.refresh) {
                        this.refresh = false;
                        this.panel = null;
                    }

                    if(this.fromFaq) {
                        this.getRows(0);                        
                    }
                })
                .catch(error => {
                    console.log(error);
                that.$refs.mySnackbar.open(that.$t("message.contract_list_error"));
                })
                .finally(() => {
                    that.contractPageLoading = false;
                });
        },
        openDialog: function(contract) {
            var that = this;

            services.apiCallerContract.listContractDetail(contract.n_contract)
                .then(response => {            
                    that.contractDialog = response.data;
                    that.contractDialog.n_contract = contract.n_contract;
                    that.goodsDialog = true;
                })
                .catch(error => {
                    console.log(error);
                that.$refs.mySnackbar.open(that.$t("message.contract_list_error"));
                });
        },
        reloadData: function() {
            this.refresh = true;
            this.loadData();
        }
    },
    created () {
        if(this.$cod_client.value) {
            this.loadData();
        }
        this.$root.$on("localeChanged", this.reloadData);
        this.$root.$on("codClientChanged", this.reloadData);
        if(this.fromFaq) {
            this.checkboxStatus = true;
            this.panel = 0;
        }
    }
}
</script>


