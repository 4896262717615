<template>
  <v-card id="goodsDetails" v-if="headers" class="pa-4" tile flat>
    <template v-for="(v, k, i) in getHeadersGrouped()">
        <v-divider v-if="i > 0" :key="'divider-' + i" class="goodsDetailsDivider"></v-divider>
        <v-row v-for="(hdr, j) in getHeadersSorted(v)" :key="'detail-' + i + '-' + j" class="goodsDetailsRow">
            <v-col class="goodsDetailsTitle">{{$t('message.' + hdr.label_on_list)}}</v-col>
            <v-col v-if="hdr.format == 'special'" class="pa-2 goodsDetailsValue">
                <span v-if="hdr.field == 'status'" v-html="getStatus(item[hdr.field])"></span>
                <span v-else-if="hdr.field == 'doc_payment_status'" v-html="getPaymentStatus(item[hdr.field], item['doc_payment_expiry'])"></span>
                <span v-else>{{ item[hdr.field] }}</span>
            </v-col>
            <v-col v-else-if="hdr.format == 'bool'" class="pa-2 goodsDetailsValue">
                <v-simple-checkbox :input-value="item[hdr.field]" value disabled></v-simple-checkbox>
            </v-col>
            <v-col v-else-if="hdr.format == 'number'" class="pa-2 goodsDetailsValue">
                {{ item[hdr.field] | formatNumber(hdr.format_show) }}
            </v-col>
            <v-col v-else-if="hdr.format == 'amount'" class="pa-2 goodsDetailsValue">
                {{ item[hdr.field] | formatAmount(hdr.format_show, contractInfo.currency_sigle) }}
            </v-col>
            <v-col v-else-if="hdr.format == 'date'" class="pa-2 goodsDetailsValue">
                {{ item[hdr.field] | formatDate(hdr.format_show, $t('message.document_date_not_available')) }}
            </v-col>
            <v-col v-else class="pa-2 goodsDetailsValue">
                {{ item[hdr.field] }}
            </v-col>
        </v-row>
    </template>
  </v-card> 
</template>
<script>
export default {
    name: 'goodsDetails',
    props: {
        headers: null,
        item: Object,
        contractInfo: null
    },
    methods: {
        getPaymentStatus: function (value, docPaymentExpiry) {
            switch (value) {
                case '1':
                    return '<span class="goodsDetails_paymentStatus_pay"></span>';
                case '2':
                    return '<span class="goodsDetails_paymentStatus_verify"></span>';
                default:
                    if (docPaymentExpiry) {
                        var dpe = new Date(docPaymentExpiry);
                        var now = new Date();
                        if (dpe >= now)
                            return '<span class="goodsDetails_paymentStatus_expiring"></span>';
                        else
                            return '<span class="goodsDetails_paymentStatus_notpay"></span>';
                    }
                    else {
                        return '<span class="goodsDetails_paymentStatus_notpay"></span>';
                    }
            }
        },
        getStatus: function (value) {
            var that = this;

            switch (value) {
                case 0:
                    if (that.contractInfo.contracts_details_icons == '1') {
                        return '<span class="goodsDetails_status_notActive_icon"><i aria-hidden="true" id="errorIcon" class="v-icon notranslate mdi mdi-close-circle-outline theme--light"></i>&nbsp;</span>';
                    }
                    else {
                        return '<span class="goodsDetails_status_notActive"></span>';
                    }
                case 1:
                    if (that.contractInfo.contracts_details_icons == '1') {
                        return '<span class="goodsDetails_status_active_icon"><i aria-hidden="true" id="errorIcon" class="v-icon notranslate mdi mdi-check-circle-outline theme--light"></i>&nbsp;</span>';
                    }
                    else {
                        return '<span class="goodsDetails_status_active"></span>';
                    }
                case 2:
                    if (that.contractInfo.contracts_details_icons == '1') {
                        return '<span class="goodsDetails_status_suspended_icon"><i aria-hidden="true" id="errorIcon" class="v-icon notranslate mdi mdi-alert-circle-outline theme--light"></i>&nbsp;</span>';
                    }
                    else {
                        return '<span class="goodsDetails_status_suspended"></span>';
                    }
                default:
                    return '';
            }
        },
        getHeadersGrouped: function () {
            return this.groupBy(this.headers, 'details_box_number');
        },
        getHeadersSorted: function (headers) {
            return headers.sort(function(a, b) {
                return a.details_box_position - b.details_box_position;
            });
        },
        groupBy: function (xs, key) {
            return xs.reduce(function(rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        }
    }
}
</script>