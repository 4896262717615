<template>
  <v-card id="goodsList" v-if="contract" class="pa-4" flat>
    <v-card-title class="goodsListTitle headline">
        {{$t('message.contracts_details_list_header').replace('%s', contract.n_contract)}}
        <v-spacer></v-spacer>
        <v-icon @click="onClickCancel">mdi-close</v-icon>
    </v-card-title>

    <v-card-text v-if="contract.contractTypes" class="goodsListContent">
      <v-expansion-panels v-for="(contractType, i) in contract.contractTypes" :key="'panel-' + i" flat>
        <v-expansion-panel flat class="ma-2 border">
          <v-expansion-panel-header class="goodsListPanelTitle" @click="getRows(contractType.kind)">
              {{ getControlPanelHeader(contractType) }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-skeleton-loader type="table" :loading="rowsLoading">
                <v-card v-if="items.length > 0" flat>
                    <v-card-title>
                      <v-spacer></v-spacer>
                      <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          :label='$t("message.good_list_search")'
                          single-line
                          hide-details
                      ></v-text-field>
                    </v-card-title>
                    <v-data-table 
                        :headers="getHeaders(contractType.headers)"
                        :items="items"
                        :search="search"
                        single-expand
                        :expanded.sync="expanded"
                        show-expand
                        @item-expanded="loadDetails"
                        :footer-props="{'items-per-page-text': $t('message.contracts_customers_num_per_page')}"
                        class="goodsListDataGrid"
                    >
                      <template v-slot:item="{ item, headers, expand, isExpanded }">
                        <tr>
                          <td v-for="(hdr, j) in headers" :key="'item-' + j" v-bind:style="{ 'text-align': hdr.align }">
                            <template v-if="hdr.value == 'data-table-expand'">
                              <v-icon :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]" 
                                  @click.stop="expand(!isExpanded)">$expand</v-icon>
                            </template>
                            <template v-else-if="hdr.format == 'special'">
                              <span v-if="hdr.value == 'status'" v-html="getStatus(item[hdr.value])"></span>
                              <span v-else-if="hdr.value == 'doc_payment_status'" v-html="getPaymentStatus(item[hdr.value], item.doc_payment_expiry)"></span>
                              <span v-else>{{ item[hdr.value] }}</span>
                            </template>
                            <span v-else-if="hdr.format == 'bool'">
                              <v-simple-checkbox :input-value="item[hdr.value]" value disabled></v-simple-checkbox>
                            </span>
                            <template v-else-if="hdr.format == 'number'">
                              {{ item[hdr.value] | formatNumber(hdr.format_show) }}
                            </template>
                            <span v-else-if="hdr.format == 'amount'">
                              {{ item[hdr.value] | formatAmount(hdr.format_show, contractInfo.currency_sigle) }}
                            </span>
                            <template v-else-if="hdr.format == 'date'">
                              {{ item[hdr.value] | formatDate(hdr.format_show, $t("message.contract_date_not_available")) }}
                            </template>
                            <template v-else>{{ item[hdr.value] }}</template>
                          </td>
                        </tr>
                      </template>

                      <template v-slot:expanded-item="{ headers }">
                        <td style="padding:0px; box-shadow:none" :colspan="headers.length">
                          <GoodsDetails v-if="detailItems" :headers="detailHeaders" :item="detailItems" :contractInfo="contractInfo"></GoodsDetails>
                        </td>
                      </template>
                    </v-data-table>
                </v-card>
                <span v-else class="goodsListListEmpty" v-html="$t('message.contracts_details_list_no_doc_text')"></span>
            </v-skeleton-loader>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>   

    <Snackbar ref="mySnackbar"></Snackbar> 
  </v-card> 
</template>

<script>
  import { services } from "../scripts/services/serviceBuilder";
  import GoodsDetails from './GoodsDetails';
  import Snackbar from './Snackbar.vue';

  export default {
    name: 'goodsList',
    components: {
      GoodsDetails,
      Snackbar
    },
    data: function () {
      return {
        headerAlign: ['center', 'start', 'end'],
        detailHeaders: [],
        detailItems: null,
        items: [],
        expanded: [],
        rowsLoading: false,
        search: ''
      };
    },
    props: {
      contract: null,
      contractInfo: null
    },
    methods: {
      getControlPanelHeader: function (contractType) {
        switch (contractType.cnt) {
          case 0:
            return this.$t('message.' + contractType.name_no_items);
          case 1:
            return this.$t('message.' + contractType.name_one_item);
          default:
            return this.$t('message.' + contractType.name_more_items);
        }
      },
      getHeaders: function (headers) {
        var that = this;

        var hdrs = [];

        headers.forEach(function(item) {
          hdrs.push({
            text: that.$t('message.' + item.title),
            align: item.alignment ? that.headerAlign[item.alignment] : 'center',
            value: item.field == "detail" ? "data-table-expand" : item.field,
            sortable : item.data_ordering == 1 ? true : false,
            class: 'secondaryColorBg white--text',
            format: item.format,
            format_show: item.format_show
          });
        });

        if (hdrs.findIndex(x => x.value == "data-table-expand") < 0) {
          hdrs.push({ 
            text: that.contract.contracts_details_list_last_label && that.contract.contracts_details_list_last_label.length > 0 ? 
                          that.contract.contracts_details_list_last_label : ' ', 
            value: 'data-table-expand',
            class: 'secondaryColorBg white--text'
          });
        }

        return hdrs;
      },
      getRows: function (kind) {
        var that = this;

        that.rowsLoading = true;

        if (that.items.length > 0)
          that.items.splice(0,that.items.length);

        services.apiCallerContract.listContractDetailKind(that.contract.n_contract, kind)
          .then((response) => {
            if (response.data.length > 0 )
              that.items = response.data;
          })
          .catch((error) => {
            console.log(error);
                that.$refs.mySnackbar.open(that.$t("message.good_list_error"));
          })
          .finally(() => {
              that.rowsLoading = false;
          });            
      },
      getPaymentStatus: function (value, docPaymentExpiry) {
        switch (value) {
          case '1':
            return '<span class="goodsList_paymentStatus_pay"></span>';
          case '2':
            return '<span class="goodsList_paymentStatus_verify"></span>';
          default:
            if (docPaymentExpiry) {
              var dpe = new Date(docPaymentExpiry);
              var now = new Date();
              if (dpe >= now)
                  return '<span class="goodsList_paymentStatus_expiring"></span>';
              else
                  return '<span class="goodsList_paymentStatus_notpay"></span>';
            }
            else {
              return '<span class="goodsList_paymentStatus_notpay"></span>';
            }
        }
      },
      getStatus: function (value) {
        var that = this;

        switch (value) {
          case 0:
            if (that.contractInfo.contracts_details_icons == '1') {
                return '<span class="goodsList_status_notActive_icon"><i aria-hidden="true" id="errorIcon" class="v-icon notranslate mdi mdi-close-circle-outline theme--light"></i>&nbsp;</span>';
            }
            else {
                return '<span class="goodsList_status_notActive"></span>';
            }
          case 1:
            if (that.contractInfo.contracts_details_icons == '1') {
                return '<span class="goodsList_status_active_icon"><i aria-hidden="true" id="errorIcon" class="v-icon notranslate mdi mdi-check-circle-outline theme--light"></i>&nbsp;</span>';
            }
            else {
                return '<span class="goodsList_status_active"></span>';
            }
          case 2:
            if (that.contractInfo.contracts_details_icons == '1') {
                return '<span class="goodsList_status_suspended_icon"><i aria-hidden="true" id="errorIcon" class="v-icon notranslate mdi mdi-alert-circle-outline theme--light"></i>&nbsp;</span>';
            }
            else {
                return '<span class="goodsList_status_suspended"></span>';
            }
          default:
            return '';
        }
      },
      loadDetails: function ({item}) {
        var that = this;

        that.detailHeaders = [];
        that.detailItems = null;

        var requests = [];
        requests.push(services.apiCallerContract.listContractDetailHeaders(item.id));
        requests.push(services.apiCallerContract.listContractDetails(item.id));

        Promise.all(requests)
          .then(responses => {
            that.detailHeaders = responses[0].data;
            if (responses[1].data && responses[1].data.length > 0)
              that.detailItems = responses[1].data[0];
          })
          .catch(error => {
            console.log(error);
                that.$refs.mySnackbar.open(that.$t("message.good_list_error"));
          });
      },
      onClickCancel: function () {
        this.$emit('goods-list-cancel');
      }
    }
  }
</script>