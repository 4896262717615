<template>
    <v-skeleton-loader type="article" tile :loading="contractDetailsLoading">
        <v-card id="contractDetails" class="pa-4" tile flat>
            <!-- <v-row class="ma-0 pl-2">
                <v-btn small>Estratto conto</v-btn>
            </v-row> -->
            <template v-for="(header, i) in headers">
                <v-row :key="'header-' + i" class="contractDetailsRow">
                    <v-col class="contractDetailsTitle">{{ $t("message." + header.label_on_list) }}</v-col>
                    <v-col v-if="header.format == 'special'" class="pa-2 contractDetailsValue">
                        <span v-if="header.field == 'status'" v-html="getStatus(item[header.field])"></span>
                        <span v-else-if="header.field == 'doc_payment_status'" v-html="getPaymentStatus(item[header.field], item['doc_payment_expiry'])"></span>
                        <span v-else>{{ item[header.field] }}</span>
                    </v-col>
                    <v-col v-else-if="header.format == 'bool'" class="pa-2 contractDetailsValue">
                        <v-simple-checkbox :input-value="item[header.field]" value disabled></v-simple-checkbox>
                    </v-col>
                    <v-col v-else-if="header.format == 'number'" class="pa-2 contractDetailsValue">
                        {{ item[header.field] | formatNumber(header.format_show) }}
                    </v-col>
                    <v-col v-else-if="header.format == 'amount'" class="pa-2 contractDetailsValue">
                        {{ item[header.field] | formatAmount(header.format_show, contractInfo.currency_sigle) }}
                    </v-col>
                    <v-col v-else-if="header.format == 'date'" class="pa-2 contractDetailsValue">
                        {{ item[header.field] | formatDate(header.format_show, $t("message.contract_date_not_available")) }}
                    </v-col>
                    <v-col v-else class="pa-2 contractDetailsValue">
                        {{ item[header.field] }}
                    </v-col>
                </v-row>
                <v-divider v-if="showDivider(i)" :key="'divider-' + i" class="contractDetailsDivider"></v-divider>
            </template>
        </v-card> 
    </v-skeleton-loader>
</template>

<script>
import { services } from "../scripts/services/serviceBuilder";
export default {
    name: 'contractDetails',
    data() {
        return {
           headers: [],
           contractDetailsLoading: true
        }
    },
    props: {
        type: null,
        item: Object,
        contractInfo: null
    },
    methods: {
        getPaymentStatus: function (value, docPaymentExpiry) {
            switch (value) {
                case '1':
                    return '<span class="contractDetails_paymentStatus_pay">' + this.$t("message.contract_payment_status_paid") + '</span>';
                case '2':
                    return '<span class="contractDetails_paymentStatus_verify">' + this.$t("message.contract_payment_status_verify") + '</span>';
                default:
                    if (docPaymentExpiry) {
                        var dpe = new Date(docPaymentExpiry);
                        var now = new Date();
                        if (dpe >= now)
                            return '<span class="contractDetails_paymentStatus_expiring">' + this.$t("message.contract_payment_status_expiring") + '</span>';
                        else
                            return '<span class="contractDetails_paymentStatus_notpay">' + this.$t("message.contract_payment_status_notpay") + '</span>';
                    }
                    else {
                        return '<span class="contractDetails_paymentStatus_notpay">' + this.$t("message.contract_payment_status_notpay") + '</span>';
                    }
            }
        },
        getStatus: function (value) {
            var that = this;

            switch (value) {
                case 0:
                    if (that.contractInfo.contracts_customers_icons == '1') {
                        return '<span class="contractDetails_status_notActive_icon"><i aria-hidden="true" id="errorIcon" class="v-icon notranslate mdi mdi-close-circle-outline theme--light"></i>&nbsp;<span>' +
                            that.$t("message.contract_status_not_active") +
                            '</span></span>';
                    }
                    else {
                        return '<span class="contractDetails_status_notActive">' + that.$t("message.contract_status_not_active") + '</span>';
                    }
                case 1:
                    if (that.contractInfo.contracts_customers_icons == '1') {
                        return '<span class="contractDetails_status_active_icon"><i aria-hidden="true" id="errorIcon" class="v-icon notranslate mdi mdi-check-circle-outline theme--light"></i>&nbsp;</span>' +
                            that.$t("message.contract_status_active") +'</span></span>';
                    }
                    else {
                        return '<span class="contractDetails_status_active">' + that.$t("message.contract_status_not_active") + '</span>';
                    }
                case 2:
                    if (that.contractInfo.contracts_customers_icons == '1') {
                        return '<span class="contractDetails_status_suspended_icon"><i aria-hidden="true" id="errorIcon" class="v-icon notranslate mdi mdi-alert-circle-outline theme--light"></i>&nbsp;</span>' +
                            that.$t("message.contract_status_suspended") +
                            '</span></span>';
                    }
                    else {
                        return '<span class="contractDetails_status_suspended">' + that.$t("message.contract_status_suspended") + '</span>';
                    }
                default:
                    return '';
            }
        },
        showDivider: function (index) {
            if(this.headers && this.headers.length > 0 ) {
                if(index != this.headers.length - 1 && 
                   this.headers[index].details_box_number != this.headers[index + 1].details_box_number)
                return true;
            }

            return false;
        }
    },
    created() {
        var that = this;

        that.contractDetailsLoading = true;
        services.apiCallerContract.listDetailHeaders(that.type)
            .then((response) => {
                that.headers = response.data;
            })
            .catch((error) => {
                that.$refs.mySnackbar.open(this.$t("message.contract_list_error"));
                console.log(error);
            })
            .finally(() => {
                that.contractDetailsLoading = false
            });
    }
}
</script>