var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.contract)?_c('v-card',{staticClass:"pa-4",attrs:{"id":"goodsList","flat":""}},[_c('v-card-title',{staticClass:"goodsListTitle headline"},[_vm._v(" "+_vm._s(_vm.$t('message.contracts_details_list_header').replace('%s', _vm.contract.n_contract))+" "),_c('v-spacer'),_c('v-icon',{on:{"click":_vm.onClickCancel}},[_vm._v("mdi-close")])],1),(_vm.contract.contractTypes)?_c('v-card-text',{staticClass:"goodsListContent"},_vm._l((_vm.contract.contractTypes),function(contractType,i){return _c('v-expansion-panels',{key:'panel-' + i,attrs:{"flat":""}},[_c('v-expansion-panel',{staticClass:"ma-2 border",attrs:{"flat":""}},[_c('v-expansion-panel-header',{staticClass:"goodsListPanelTitle",on:{"click":function($event){return _vm.getRows(contractType.kind)}}},[_vm._v(" "+_vm._s(_vm.getControlPanelHeader(contractType))+" ")]),_c('v-expansion-panel-content',[_c('v-skeleton-loader',{attrs:{"type":"table","loading":_vm.rowsLoading}},[(_vm.items.length > 0)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t("message.good_list_search"),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"goodsListDataGrid",attrs:{"headers":_vm.getHeaders(contractType.headers),"items":_vm.items,"search":_vm.search,"single-expand":"","expanded":_vm.expanded,"show-expand":"","footer-props":{'items-per-page-text': _vm.$t('message.contracts_customers_num_per_page')}},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":_vm.loadDetails},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('tr',_vm._l((headers),function(hdr,j){return _c('td',{key:'item-' + j,style:({ 'text-align': hdr.align })},[(hdr.value == 'data-table-expand')?[_c('v-icon',{class:['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }],on:{"click":function($event){$event.stopPropagation();return expand(!isExpanded)}}},[_vm._v("$expand")])]:(hdr.format == 'special')?[(hdr.value == 'status')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.getStatus(item[hdr.value]))}}):(hdr.value == 'doc_payment_status')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.getPaymentStatus(item[hdr.value], item.doc_payment_expiry))}}):_c('span',[_vm._v(_vm._s(item[hdr.value]))])]:(hdr.format == 'bool')?_c('span',[_c('v-simple-checkbox',{attrs:{"input-value":item[hdr.value],"value":"","disabled":""}})],1):(hdr.format == 'number')?[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item[hdr.value],hdr.format_show))+" ")]:(hdr.format == 'amount')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatAmount")(item[hdr.value],hdr.format_show, _vm.contractInfo.currency_sigle))+" ")]):(hdr.format == 'date')?[_vm._v(" "+_vm._s(_vm._f("formatDate")(item[hdr.value],hdr.format_show, _vm.$t("message.contract_date_not_available")))+" ")]:[_vm._v(_vm._s(item[hdr.value]))]],2)}),0)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [_c('td',{staticStyle:{"padding":"0px","box-shadow":"none"},attrs:{"colspan":headers.length}},[(_vm.detailItems)?_c('GoodsDetails',{attrs:{"headers":_vm.detailHeaders,"item":_vm.detailItems,"contractInfo":_vm.contractInfo}}):_vm._e()],1)]}}],null,true)})],1):_c('span',{staticClass:"goodsListListEmpty",domProps:{"innerHTML":_vm._s(_vm.$t('message.contracts_details_list_no_doc_text'))}})],1)],1)],1)],1)}),1):_vm._e(),_c('Snackbar',{ref:"mySnackbar"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }